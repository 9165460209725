import './quiz.page.scss'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../state'
import { TagSelector } from '../../../component/tag-selector'
import DatePicker from 'react-datepicker'
import hu from 'date-fns/locale/hu'
import { TextField } from '../../../component/field'
import { Dropdown } from '../../../component'
import { AddImage } from '../../../component/add-image'
import { useState } from 'react'
import { MediaLibrary } from '../../../component/media-library'
import { MediaLibraryFile } from '../../../entity'
import { ImageEditor } from '../../../component/image-editor/image-editor'
import { TextArea } from '../../../component/textarea'
import { DescriptionTextArea } from '../../../component/textarea/description'
import { saveQuiz, saveTest, sendQuizPush, sendTestPush } from '../../../action/quizes'
import { QuizQuestion } from '../../../component/quiz-question'
import { TestResultsModal } from '../../../component/test-results-modal'
import { message } from '../../../helper'
import { MessageModal } from '../../../component/modal'
import { AchievementModal } from '../../../component/achievement-modal'


export const Quiz = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { quiz, quizTopics, type } = useSelector( ( state: State ) => state.quizes )

    const [ showMediaLibrary, setShowMediaLibrary ] = useState( false )
    const [ showImageEditor, setShowImageEditor ] = useState( false )
    const [ file, setFile ] = useState( null as MediaLibraryFile | null )
    const [ showTestResultsModal, setShowTestResultsModal ] = useState( false )

    const [ imageType, setImageType ] = useState( null as string | null )
    const [ selectedTab, setSelectedTab ] = useState( `quiz` )
    const [ push, setPush ] = useState( `` )


    const [ infoModalVisibility, setInfoModalVisibility ] = useState( false )
    const [ infoModalTitle, setInfoModalTitle ] = useState( `` )
    const [ infoModalDescription, setInfoModalDescription ] = useState( `` )

    const [ showAchievementModal, setShowAchievementModal ] = useState( false )
    const [ achievementData, setAchievementData ] = useState( null as any )

    const openAchievementModal = () => {

        if ( !quiz!.id || quiz!.achievementGroup === null ) {

            setAchievementData( { type: 1, category: 3, activity: 13, showHeader: false, showTypeSelect: false, bronzeDesiredValue: 1, silverDesiredValue: 31, goldDesiredValue: 61, ...achievementData } )

        } else {

            setAchievementData( { type: quiz!.achievementGroup.type, showHeader: false, showTypeSelect: false, name: quiz!.achievementGroup.name, description: quiz!.achievementGroup.description, id: quiz!.achievementGroup.id, goldExp: quiz!.achievementGroup.goldAchievement.experience, goldImage: quiz!.achievementGroup.goldAchievement.image, bronzeExp: quiz!.achievementGroup.bronzeAchievement!.experience, bronzeImage: quiz!.achievementGroup.bronzeAchievement!.image, silverExp: quiz!.achievementGroup.silverAchievement!.experience, silverImage: quiz!.achievementGroup.silverAchievement!.image, ...achievementData } )

        }

        setShowAchievementModal( true )

    }

    const onSaveAchievement = async ( data: any ) => {

        setAchievementData( data )
        setShowAchievementModal( false )

    }

    const checkFields = (): boolean => {

        const typeString = type === `quiz` ? `kvíz` : `teszt`

        if ( quiz!.visibleTo === null ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `Az elrejtés időpontjának megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( quiz!.image === null ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A ${ typeString } képének kiválasztása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( ! quiz!.title || quiz!.title.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A ${ typeString } címének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( ! quiz!.lead || quiz!.lead.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A ${ typeString } lead szövegének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( ! quiz!.description || quiz!.description.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A ${ typeString } szövegének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        for ( const question of quiz!.questions ) {

            if ( ! question.title || question.title.trim().length === 0 ) {

                setInfoModalTitle( `Figyelmeztetés` )
                setInfoModalDescription( `A kérdés megadása mindenhol kötelező!` )
                setInfoModalVisibility( true )

                return false

            }

            if ( question.type === 1 && ( question.image === null ) ) {

                setInfoModalTitle( `Figyelmeztetés` )
                setInfoModalDescription( `A kérdés képének megadása kötelező a következő tartalomnál: "${ question.title }"!` )
                setInfoModalVisibility( true )

                return false

            }

            if ( question.type === 2 && ( ! question.videoUrl || question.videoUrl.trim().length === 0 ) ) {

                setInfoModalTitle( `Figyelmeztetés` )
                setInfoModalDescription( `A kérdés videó urljének megadása kötelező a következő tartalomnál: "${ question.title }"!` )
                setInfoModalVisibility( true )

                return false

            }

            for ( let i = 0; i < 4; i++ ) {

                const condition = ( question.type === 3 ) ? ( question.answers[ i ].image === null ) : ( ! question.answers[ i ].title || question.answers[ i ].title.trim().length === 0 )

                if ( condition ) {

                    setInfoModalTitle( `Figyelmeztetés` )
                    setInfoModalDescription( `Minden válaszlehetőségének megadása kötelező a következő tartalomnál: "${ question.title }"!` )
                    setInfoModalVisibility( true )

                    return false

                }

            }

        }

        return true

    }


    const onAddQuestion = () => {

        dispatch( { type: `CREATE_QUIZ_QUIZ_QUESTION` } )

    }

    const onAddImage = ( image: string ) => {

        setImageType( image )
        setShowMediaLibrary( true )

    }

    const onDeleteImage = ( image: string ) => {

        switch ( image ) {

            default:
                u( `image`, null )

        }

    }

    const onImageSelected = ( file: MediaLibraryFile ) => {

        switch ( imageType ) {

            default:
                u( `image`, file )
                setShowImageEditor( false )

        }

        setShowImageEditor( false )

    }

    const u = ( field: string, value: any ) => {

        switch ( field ) {

            case `answerExperience`:
                if ( isNaN( parseInt( value ) ) ) value = 25
                if ( parseInt( value ) < 1 ) value = 1
                if ( parseInt( value ) > 50 ) value = 50
                value = parseInt( value )
                break

            case `experience`:
                if ( isNaN( parseInt( value ) ) ) value = 150
                if ( parseInt( value ) < 100 ) value = 100
                if ( parseInt( value ) > 250 ) value = 250
                value = parseInt( value )
                break

        }

        dispatch( { type: `UPDATE_QUIZ`, field: field, value: value } )

    }

    const sendPush = async () => {

        const result = ( type === `quiz` ) ? await sendQuizPush( quiz!.id, push ) : await sendTestPush( quiz!.id, push )
        if ( result ) {

            message( `Az értesítés sikeresen elküldésre került!` )
            setPush( `` )

        } else {

            alert( `Sajnos a küldés nem sikerült. Kérlek próbáld újra később!` )

        }

    }

    const onSave = async () => {

        if ( !checkFields() ) return

        let c = null

        c = type === `quiz` ? await saveQuiz( { ...quiz, achievementData: achievementData } ) : await saveTest( quiz )

        if ( c !== null ) {
            navigate( `/kvizek-es-tesztek/${ type === `quiz` ? `kvizek` : `tesztek` }/${ c.id }` )
        }

    }

    const onDeleteAchievement = async () => {

        setAchievementData( {} )
        quiz!.achievementGroup = null
        setShowAchievementModal( false )

    }

    return (

        <main className="page page--quiz">

            <div className="main-div">

                <div className="navigation-title">

                    <NavLink to="/kvizek-es-tesztek">

                        <h1 className="title-text-h1">Kvízek &gt;</h1>

                    </NavLink>

                    <NavLink to="/kvizek-es-tesztek/lista">

                        <h1 className="title-text-h1">Kvízek lista &gt;</h1>

                    </NavLink>

                    <h1 className="title-text-h1">{ quiz!.id === undefined ? `Új ${ type === `quiz` ? `kvíz` : `teszt` }` : `${ type === `quiz` ? `Kvíz` : `Teszt` } szerkesztése` }</h1>

                </div>

                <div className="toolbar">

                    <NavLink to="/kvizek-es-tesztek/lista">

                        <button className="button button--ghost">Vissza</button>

                    </NavLink>

                    <button className="button button--primary" onClick={ onSave }>Mentés</button>

                </div>

                <div className="grid">

                    <div className="card-div card-div--00">

                        <div className="cols">

                            <div className="col">

                                <div className="caption">Megjelenés időpontja *</div>
                                <DatePicker
                                    selected={ new Date( quiz!.visibleFrom * 1000 ) }
                                    onChange={ ( date ) => u( `visibleFrom`, date ? Math.round( date.getTime() / 1000 ) : null ) }
                                    showTimeSelect
                                    timeIntervals={ 1 }
                                    timeFormat="HH:mm"
                                    dateFormat="yyyy. MMMM dd. - HH:mm"
                                    locale={ hu } />

                            </div>

                            <div className="col">

                                <div className="caption">Elrejtés időpontja *</div>
                                <DatePicker
                                    selected={ quiz!.visibleTo === null ? null : new Date( quiz!.visibleTo * 1000 ) }
                                    onChange={ ( date ) => u( `visibleTo`, date ? Math.round( date.getTime() / 1000 ) : null ) }
                                    showTimeSelect
                                    timeIntervals={ 1 }
                                    minDate={ new Date( quiz!.visibleFrom * 1000 ) }
                                    timeFormat="HH:mm"
                                    dateFormat="yyyy. MMMM dd. - HH:mm"
                                    locale={ hu } />

                            </div>

                            <div className="col">

                                <div className="caption">Státusz *</div>
                                <div className="options">

                                    <div className="option">

                                        <input checked={ quiz!.isDraft } type="radio" value={ `true` } name="isDraft" onClick={ e => u( `isDraft`, true ) } id="isDraftTrue" />
                                        <label htmlFor="isDraftTrue">Sablon</label>

                                    </div>

                                    <div className="option">

                                        <input checked={ !quiz!.isDraft } type="radio" name="isDraft" value={ `false` } onClick={ e => u( `isDraft`, false ) } id="isDraftFalse" />
                                        <label htmlFor="isDraftFalse">Aktív</label>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="card-div card-div--01">

                        <TagSelector tags={ quiz!.tags } onTagAdd={ tag => dispatch( { type: `QUIZ_ADD_TAG`, tag } ) } onTagDelete={ tag => dispatch( { type: `QUIZ_REMOVE_TAG`, tag } ) } />

                    </div>

                    <div className="card-div card-div--02">

                        <div className="cols">

                            <div className="col">

                                <img alt="" src="/image/exp.svg" width={ 87 } />

                            </div>

                            { type === `quiz` ? (

                                <div className="col">

                                    <div className="caption">Tapasztalati pont a jó válaszért (1-50 TP) *</div>
                                    <TextField field={ { value: quiz!.answerExperience.toString(), error: `` } } onChange={ e => u( `answerExperience`, e.target.value ) } />

                                </div>

                            ) : null }

                            <div className="col">

                                <div className="caption">Tapasztalati pont a { type === `quiz` ? `kvízért` : `tesztért` } (100-250 TP) *</div>
                                <TextField field={ { value: quiz!.experience.toString(), error: `` } } onChange={ e => u( `experience`, e.target.value ) } />

                            </div>

                        </div>

                    </div>

                    <div className="card-div card-div--03">

                        <div className="cols">

                            <div className="col">

                                <div className="caption">Téma kiválasztása *</div>
                                <Dropdown value={ quiz!.quizTopic === null ? null : quiz!.quizTopic.id } items={ quizTopics.map( quizTopic => ( { id: quizTopic.id, value: quizTopic.name } ) ) } onChange={ ( id: number ) => u( `quizTopic`, quizTopics.find( topic => topic.id === id ) ) } compact={ false } />

                            </div>

                        </div>

                    </div>

                    <div className="card-div card-div--04">

                        <div className="cols">

                            <div className="col">

                                <div className="caption">Kiemelt { type === `quiz` ? `kvíz` : `teszt` }</div>
                                <button className={ `button ${ quiz!.isHighlighted ? `button--primary` : `button--ghost` }` } onClick={ () => u( `isHighlighted`, !quiz!.isHighlighted ) }>{ quiz!.isHighlighted ? `${ type === `quiz` ? `Kvíz` : `Teszt` } kiemelve` : `${ type === `quiz` ? `Kvíz` : `Teszt` } kiemelése` }</button>

                            </div>

                        </div>

                    </div>

                    { type === `test` ? (

                        <div className="card-div card-div--06">

                            <div className="cols">

                                <div className="col">

                                    <div className="caption">Teszt eredmények</div>
                                    <button className="button button--ghost" onClick={ () => setShowTestResultsModal( true ) }>Teszt eredmények szerkesztése</button>

                                </div>

                            </div>

                        </div>

                    ) : (

                        <div className="card-div card-div--06">

                            <div className="cols">

                                <div className="col">

                                    <div className="caption">Kitüntetés</div>
                                    <button className="button button--ghost" onClick={ openAchievementModal }>Kitüntetés { ( !quiz!.id || quiz!.achievementGroup === null ) && achievementData && Object.keys(achievementData).length === 0 ? `hozzáadása` : `szerkesztése` }</button>

                                </div>

                            </div>

                        </div>

                    ) }

                    <div className="card-div card-div--tabs">

                        <div className="tabs">

                            <div className={ `tab ${ selectedTab === `quiz` ? `tab--active` : `` }` } onClick={ () => setSelectedTab( `quiz` ) }>

                                { type === `quiz` ? `Kvíz` : `Teszt` }

                            </div>

                            <div className={ `tab ${ selectedTab === `results` ? `tab--active` : `` }` } onClick={ () => setSelectedTab( `results` ) }>

                                Válaszok

                            </div>

                        </div>

                    </div>

                    { selectedTab === `quiz` ? (

                        <>

                            <div className="card-div card-div--05">

                                <div className="caption">{ type === `quiz` ? `Kvíz` : `Teszt` }ek tartalmi elemeinek megadása</div>

                                <div className="cols">

                                    <div className="col course-image">

                                        <div className="caption">Kép hozzáadása * (16:9)</div>
                                        <AddImage imageUrl={ quiz!.image === null ? `` : quiz!.image.url! } text="Kép hozzáadása" width="215px" height="121px" onAddImage={ () => onAddImage( `image` ) } onDeleteImage={ () => onDeleteImage( `image` ) } />

                                    </div>

                                    <div className="col course-title">

                                        <div className="caption">A { type === `quiz` ? `kvíz` : `teszt` } címe *</div>
                                        <div className="info">Ajánlott karakter szám maximum: 120 karakter</div>
                                        <TextField field={ { value: quiz!.title, error: `` } } onChange={ e => u( `title`, e.target.value ) } />

                                    </div>

                                </div>

                                <div className="cols">

                                    <div className="col">

                                        <div className="caption">{ type === `quiz` ? `Kvíz` : `Teszt` } lead szöveg megadása *</div>
                                        <TextArea field={ { value: quiz!.lead, error: `` } } onChange={ e => u( `lead`, e.target.value ) } />

                                    </div>

                                </div>

                                <div className="cols">

                                    <div className="col">

                                        <div className="caption">{ type === `quiz` ? `Kvíz` : `Teszt` } szövegének megadása *</div>
                                        <DescriptionTextArea field={ { value: quiz!.description, error: `` } } onChange={ html => u( `description`, html ) } />

                                    </div>

                                </div>

                            </div>

                            { quiz!.questions.map( question => (

                                <QuizQuestion question={ question } />

                            ) ) }

                            <footer>

                                <div className="new-question" onClick={ onAddQuestion }>

                                    <img alt="" src="/image/add.svg" />
                                    <span>Új kérdés hozzáadása</span>

                                </div>

                            </footer>

                        </>

                    ) : (

                        <>

                            <div className="caption">{ quiz!.questions.reduce( ( max, question ) => {
                                const current = question.answers.reduce((sum, answer) => sum + answer.userAnswers, 0)
                                return current > max ? current : max
                            }, 0 ) } válasz érkezett</div>

                            <div className="card-div card-div--push">

                                <div className="caption">“Push üzenet” küldése</div>
                                <TextArea field={{ value: push, error: `` }} onChange={ e => setPush( e.target.value ) } />
                                <div className="footer">

                                    <button className="button button--primary" onClick={ sendPush }>Mentés</button>

                                </div>

                            </div>

                            { quiz!.questions.map( question => (

                                <div className="card-div card-div--result">

                                    <div className="caption">

                                        { question.title }

                                    </div>

                                    <div className="answers">

                                        { question.answers.map( answer => (

                                            <div className="answer">

                                                <div className="answer__title caption">{ answer.title }</div>
                                                <div className="answer__bar">

                                                    <div className="bar">
                                                        <div className="percentage" style={{ width: `${ question.answers.reduce( ( sum, answer ) => sum + answer.userAnswers, 0 ) === 0 ? 0 : Math.round( answer.userAnswers / question.answers.reduce( ( sum, answer ) => sum + answer.userAnswers, 0 ) * 100 ) }%` }}></div>
                                                    </div>

                                                    <div className="info">{ answer.userAnswers } - { question.answers.reduce( ( sum, answer ) => sum + answer.userAnswers, 0 ) === 0 ? 0 : Math.round( answer.userAnswers / question.answers.reduce( ( sum, answer ) => sum + answer.userAnswers, 0 ) * 100 ) }%</div>

                                                </div>

                                            </div>

                                        ) ) }

                                    </div>

                                </div>

                            ) ) }

                        </>

                    ) }

                </div>

            </div>

            { showMediaLibrary ? (

                <MediaLibrary onMediaSelected={ ( file: MediaLibraryFile ) => {

                    setFile( file )
                    setShowMediaLibrary( false )
                    setShowImageEditor( true )

                } } onClose={ () => setShowMediaLibrary( false ) } />

            ) : null }

            { showImageEditor ? (

                <ImageEditor aspectRatio={ { name: `${ type === `quiz` ? `Kvíz` : `Teszt` } képe`, ratio: 16 / 9 } } mediaLibraryFile={ file! } onReady={ f => onImageSelected( f ) } onClose={ () => setShowImageEditor( false ) } />

            ) : null }

            { showTestResultsModal ? (

                <TestResultsModal results={ quiz?.possibleResults! } onClose={ () => setShowTestResultsModal( false ) } />

            ) : null }

            { infoModalVisibility ? (

                <MessageModal title={ infoModalTitle } message={ infoModalDescription } onCloseClick={ () => setInfoModalVisibility( false ) } />

            ) : null }

            { showAchievementModal ? (

                <AchievementModal selectedAchievement={ achievementData } onClose={ () => setShowAchievementModal( false ) } onSave={ onSaveAchievement } onDelete={ onDeleteAchievement } />

            ) : null }

        </main>

    )

}
