import { store } from '../../store'


export const saveEvent = async ( achievementData: any ) => {

    let response
    const hostPoliticians = store.getState().events.hostPoliticians
    const form = store.getState().events.form

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/events/${ form.id === null ? `` : form.id }`, {
            body: JSON.stringify( {
                achievementData: achievementData,
                startDate: new Date( form.startDate.value ).getTime() / 1000,
                endDate: new Date( form.endDate.value ).getTime() / 1000,
                visibility: parseInt( form.visibility.value ),
                hostPoliticians: hostPoliticians,
                title: form.title.value,
                description: form.description.value,
                liveUrl: form.isOnline.value === false || form.liveUrl.value === null || form.liveUrl.value.trim().length === 0 ? null : form.liveUrl.value,
                location: form.isOnline.value === true ? ` ` : form.location.value,
                imageFileId: form.imageFile.value?.id,
                lat: parseFloat( form.lat.value ),
                lng: parseFloat( form.lng.value ),
                tags: form.tags,
                summary: form.summary.value,
                isOnline: form.isOnline.value
            } ),
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'post'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'SAVE_EVENT_TIMEOUT' } )
        return false

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'SAVE_EVENT_UNAUTHORIZED' } )
        return false

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'SAVE_EVENT_UNEXPECTEDRESPONSE' } )
        return false

    }

    store.dispatch( { type: 'SAVE_EVENT_OK' } )
    return true

}
